import axios from 'axios';
import queryString from 'query-string';

import ENV from '@/env';
import * as api from '@/lib/api';
import logger from '@/lib/logger';
import { isKioskRoute } from '@/lib/routes';
import { withType } from '@/lib/util';
import { Toast } from '@/types/toast';

import * as TYPES from './action_types';
import { loginWithToken } from './auth';
import { getBackgroundJobs } from './background_jobs';
import { toggleLibraryView } from './folders';

export const declareKioskMode = () => {
	return withType(TYPES.SET_KIOSK_MODE, {
		kioskMode: true,
	});
};

export const setKioskRoot = root => withType(TYPES.SET_KIOSK_ROOT, { root });
export const setAssetTypeFilter = assetType => withType(TYPES.SET_ASSET_TYPE_FILTER, { assetType });

export const setKioskTopNavTransparency = transparency =>
	withType(TYPES.SET_KIOSK_TOP_NAV_TRANSPARENCY, { transparency });

export const setAuthRedirect = location => {
	return withType(TYPES.AUTH_SET_REDIRECT, {
		payload: {
			pathname: location.pathname,
			query: location.query,
		},
	});
};

export const clearAuthRedirect = () => {
	return withType(TYPES.AUTH_SET_REDIRECT, {
		payload: null,
	});
};

export const refresh = () => (dispatch, getState, container) => {
	if (getState().refreshing) {
		// Prevent double refresh
		return;
	}

	dispatch(
		withType(TYPES.REFRESHING, {
			refreshing: true,
		}),
	);

	setTimeout(() => {
		dispatch(
			withType(TYPES.REFRESHING, {
				refreshing: false,
			}),
		);
	}, 0);
};

export const initializeUserBackgroundJobs = (dispatch, getState, container) => {
	return dispatch(getBackgroundJobs());
};

export const setContentBounds =
	(bounds, namespace = 'content') =>
	(dispatch, getState, container) => {
		return dispatch(withType(TYPES.SET_CONTENT_BOUNDS, { bounds, namespace }));
	};

export const getAppSettings = () => (dispatch, getState, container) => {
	dispatch(withType(TYPES.GET_APP_SETTINGS_START));

	return api.getAppSetings(container.http).then(res => {
		dispatch(withType(TYPES.GET_APP_SETTINGS_END, { data: res }));
	});
};

export const getRawAppSettings = () => (dispatch, getState, container) => {
	dispatch(withType(TYPES.GET_RAW_APP_SETTINGS_START));

	return api.getRawAppSetings(container.http).then(res => {
		dispatch(withType(TYPES.GET_RAW_APP_SETTINGS_END, { raw: res }));
	});
};

export const setAppSettings = payload => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SET_APP_SETTINGS_START));

	return api.setAppSetings(container.http, payload).then(res => {
		dispatch(withType(TYPES.SET_APP_SETTINGS_END, { data: res }));
		dispatch(addToast(new Toast('Your changes were saved!')));
	});
};

export const setAppSettingsLandingImage = imageId => (dispatch, getState, container) => {
	dispatch(withType(TYPES.APP_SETTINGS_SET_LANDING_IMAGE));
	const state = getState();
	const payload = {
		id: state.appSettings ? state.appSettings.id : null,
		setting: 'hero_image',
		value: imageId,
	};

	return api.setAppSettingsLandingImage(container.http, payload).then(res => {
		dispatch(withType(TYPES.GET_APP_SETTINGS_END, { data: res }));
	});
};

const initializeLocationTracking = (dispatch, getstate, container) => {
	// Seed current location
	dispatch(
		withType(TYPES.NAVIGATE_TO_LOCATION, {
			location: container.history.location,
		}),
	);

	container.history.listen(location => {
		dispatch(withType(TYPES.NAVIGATE_TO_LOCATION, { location }));
	});
};

const initializeKioskMode = (dispatch, getState, container) => {
	const kioskMode = isKioskRoute(container.history.location.pathname);
	if (kioskMode) {
		// kioskMode is by default off, we only need to set it if true
		dispatch(declareKioskMode());
	}
};

export const restoreSessionFromCookie = (dispatch, getState, container) => {
	// Load cookie
	const token = container.cookie.get(ENV.api.session_cookie);
	if (!token) {
		return Promise.resolve();
	}
	return dispatch(loginWithToken(token));
};

const initializeVersionCheck = (dispatch, getState, container) => {
	if (!ENV.version_checker.update_interval) {
		return null;
	}
	setInterval(updateVersion, ENV.version_checker.update_interval);
	return updateVersion();

	function updateVersion() {
		return axios({ url: '/build.json' })
			.then(res => res.data)
			.catch(() => api.version(container.http))
			.then(res => {
				return dispatch(
					withType(TYPES.APP_VERSION_UPDATE, {
						version: res.version,
					}),
				);
			})
			.catch(err => {
				logger.error(err);
			});
	}
};

/**
 * Clear flash storage on any route change
 */
const initializeFlashStorage = (dispatch, getState, /** Container */ container) => {
	dispatch(withType(TYPES.FLASH_STORAGE_CLEAR));
};

/**
 * Load local storage values into the store
 */
const initializeLocalStorage = (dispatch, getState, /** Container */ container) => {
	dispatch(withType(TYPES.LOCAL_STORAGE_READ));
};

/**
 * Pickup last LibraryView
 */
const restoreSavedLibraryView = (dispatch, getState, /** Container */ container) => {
	const state = getState();
	const { libraryView } = state.localStorage;
	dispatch(toggleLibraryView(libraryView));
};

const initializeAppSettings = (dispatch, getState, container) => {
	return dispatch(getAppSettings());
};

export const initializeRollbarTracking = auth => (dispatch, getState, container) => {
	dispatch(withType(TYPES.INITIALIZE_ROLLBAR_TRACKING, auth));
};

export const initialize =
	() =>
	(...args) => {
		const dispatch = args[0];
		const initPromises = [
			initializeAppSettings(...args),
			initializeLocationTracking(...args),
			initializeKioskMode(...args),
			restoreSessionFromCookie(...args),
			initializeVersionCheck(...args),
			initializeFlashStorage(...args),
			initializeLocalStorage(...args),
			restoreSavedLibraryView(...args),
		];

		return Promise.all(initPromises).then(
			() => {
				dispatch(withType(TYPES.INITIALIZED));
			},
			error => {
				dispatch(withType(TYPES.FATAL_ERROR, { error }));
			},
		);
	};

export const throwFatalError = error => dispatch => {
	setTimeout(() => dispatch(resetAllOps()), 1000 * 15);
	return dispatch(withType(TYPES.FATAL_ERROR, { error }));
};

export const setRouteTitle = (name, title) => {
	return withType(TYPES.SET_ROUTE_TITLE, {
		name,
		title,
	});
};

export const downloadImages = ids => (dispatch, getState, container) => {
	return api.downloadImages(container.http, ids).then(
		res => {
			const url = api.preparedDownloadUrl(res.id);
			window.location.href = url;
		},
		error => {
			dispatch(addToast(Toast.error(error, `Failed to prepare files download`)));
		},
	);
};

export const uploadImages = (files, options) => (dispatch, getState, container) => {
	return api.uploadImages(container.http, files, options);
};

export const uploadVideos = (files, options) => (dispatch, getState, container) => {
	return api.uploadVideos(container.http, files, options);
};

export const uploadDocuments = (files, options) => (dispatch, getState, container) => {
	return api.uploadDocuments(container.http, files, options);
};

export const navigateToQuery = queryOb => (dispatch, getState, container) => {
	const { location } = container.history;

	if (queryOb.serialize) {
		queryOb = queryOb.serialize();
	}
	let query = { ...queryString.parse(location.search), ...queryOb };
	query = Object.fromEntries(
		Object.entries(query).filter(([key, value]) => {
			return value !== undefined && value !== '' && !Number.isNaN(value);
		}),
	);

	container.history.push({
		...location,
		search: queryString.stringify(query),
	});
};

export const dismissToast = id => {
	return withType(TYPES.TOAST_DISMISS, { id });
};

export const addToast = (toast, type, title) => {
	if (typeof toast === 'string') {
		toast = new Toast(toast, type, title);
	}
	return withType(TYPES.TOAST_ADD, { toast });
};

export const addToastWarning = (toast, title) => {
	if (typeof toast === 'string') {
		toast = Toast.warning(toast, title);
	}
	return withType(TYPES.TOAST_ADD, { toast });
};

export const addToastError = (toast, title) => {
	if (typeof toast === 'string') {
		toast = Toast.error(toast, title);
	}
	return withType(TYPES.TOAST_ADD, { toast });
};

export const resetAllOps = () => {
	return withType(TYPES.OP_RESET_ALL);
};

export const resetAllStates = targets => {
	if (targets && !Array.isArray(targets)) {
		targets = [targets];
	}
	return withType(TYPES.DATA_RESET_ALL, { targets });
};

export const toggleMobileMenu = () => {
	return withType(TYPES.TOGGLE_MOBILE_MENU);
};

/**
 * Navigate to previousLocation, or to fallbackLocation, if not available
 * @param {string} fallbackLocation
 */
export const navigateBackWithFallback = fallbackLocation => (dispatch, getState, container) => {
	const state = getState();

	container.history.push(state.previousLocation || fallbackLocation);
};
