import ENV from '@/env';
import * as api from '@/lib/api';
import * as routes from '@/lib/routes';
import { withType } from '@/lib/util';
import { Toast, TOAST_TYPES } from '@/types/toast';

import * as TYPES from './action_types';
import { addToast, initializeRollbarTracking } from './application';

export const authOpReset = () => {
	return withType(TYPES.AUTH_OP_RESET);
};

export const loginWithCredentials = (username, password) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.LOGIN_START));
	if (!(username && password)) {
		dispatch(withType(TYPES.LOGIN_END, { error: { message: 'All fields are required' } }));
		return;
	}

	return api
		.login(container.http, username, password)
		.then(seswd => {
			dispatch(withType(TYPES.AUTH_GOT_SESSION, seswd));
			dispatch(withType(TYPES.LOGIN_END));

			const token = seswd.session.access_token;
			container.cookie.set(ENV.api.session_cookie, token, {
				domain: window.location.hostname,
				path: '/',
				secure: process.env.NODE_ENV === 'production',
			});
			if (container.socket) {
				const socketUrl = container.socket._socket.url;
				socketUrl.query = `${socketUrl.query || ''}&_token=${token}`;
				container.socket.open(socketUrl.toString());
			}
			// container.cookie.set(ENV.api.session_cookie, seswd.session.access_token, {
			// 	domain: new URL(assetRouterBaseUrl()).hostname,
			// 	path: '/',
			// 	secure: process.env.NODE_ENV === 'production',

			// });

			// console.log({
			// 	domain: new URL(ENV.api.base_url).hostname,
			// 	secure: process.env.NODE_ENV === 'production',
			// });
			// console.log({
			// 	domain: new URL(assetRouterBaseUrl()).hostname,
			// 	secure: process.env.NODE_ENV === 'production',
			// });

			dispatch(initializeRollbarTracking(seswd));
		})
		.catch(error => {
			dispatch(withType(TYPES.LOGOUT));
			dispatch(withType(TYPES.LOGIN_END, { error }));
		});
};

export const loginWithToken = token => (dispatch, getState, container) => {
	dispatch(withType(TYPES.LOGIN_START));

	return api.getSessionByToken(container.http, token).then(
		seswd => {
			dispatch(withType(TYPES.AUTH_GOT_SESSION, seswd));
			dispatch(withType(TYPES.LOGIN_END));
			dispatch(initializeRollbarTracking(seswd));

			if (container.socket) {
				const socketUrl = container.socket._socket.url;
				socketUrl.query = `${socketUrl.query || ''}&_token=${token}`;
				container.socket.open(socketUrl.toString());
			}
		},
		error => {
			dispatch(withType(TYPES.LOGIN_END, { error }));
		},
	);
};

export const logout = () => (dispatch, getState, container) => {
	return api
		.logout(container.http)
		.catch(error => console.error(error))
		.finally(() => {
			dispatch(withType(TYPES.DATA_RESET_ALL));
			dispatch(withType(TYPES.LOGOUT));
			container.history.push(routes.DEFAULT_LOGGED_OUT);
			container.cookie.remove(ENV.api.session_cookie, {
				domain: window.location.hostname,
				path: '/',
			});
		});
};

export const switchSessionCompany = toCompanyId => (dispatch, getState, container) => {
	dispatch(withType(TYPES.SWITCH_COMPANY_START));

	return api.switchSessionCompany(container.http, toCompanyId).then(
		seswd => {
			// Go home before we update auth. Since use rights will change now.
			container.history.push(routes.DEFAULT_LOGGED_IN);

			// Stop any running uploads
			dispatch(withType(TYPES.UPLOADS_RESET));

			// Replace running session
			dispatch(withType(TYPES.AUTH_GOT_SESSION, seswd));
			dispatch(withType(TYPES.SWITCH_COMPANY_END));
			dispatch(addToast(new Toast(`You are now working as "${seswd.cwd.company.name}".`)));
			dispatch(initializeRollbarTracking(seswd));
		},
		error => {
			// No error provided in the op, we'll just show it here
			dispatch(
				addToast(new Toast(`Failed to switch company: ${error.message}`, TOAST_TYPES.danger)),
			);
			dispatch(withType(TYPES.SWITCH_COMPANY_END));
		},
	);
};

export const changePassword = (oldPassword, newPassword) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.CHANGE_PASSWORD_START, { currentOp: TYPES.CHANGE_PASSWORD_START }));

	return api.changePassword(container.http, oldPassword, newPassword).then(
		() => {
			dispatch(withType(TYPES.CHANGE_PASSWORD_END));
			dispatch(logout());
			dispatch(
				addToast(
					new Toast(`Password has been changed. You can now log in with your new password.`),
				),
			);
		},
		error => {
			dispatch(withType(TYPES.CHANGE_PASSWORD_END, { error }));
		},
	);
};

export const resetPasswordRequest = email => (dispatch, getState, container) => {
	dispatch(
		withType(TYPES.RESET_PASSWORD_REQUEST_START, {
			currentOp: TYPES.RESET_PASSWORD_REQUEST_START,
		}),
	);

	return api.resetPasswordRequest(container.http, email).then(
		() => {
			dispatch(withType(TYPES.RESET_PASSWORD_REQUEST_END));
			dispatch(
				addToast(
					new Toast(
						`We have e-mailed you a reset password link. You should receive it within a few minutes.`,
					),
				),
			);
			// container.history.push(routes.AUTH_LOGIN);
		},
		error => {
			dispatch(withType(TYPES.RESET_PASSWORD_REQUEST_END, { error }));
		},
	);
};

export const resetPasswordExecute = (token, newPassword) => (dispatch, getState, container) => {
	dispatch(withType(TYPES.RESET_PASSWORD_EXECUTE_START));

	return api.resetPasswordExecute(container.http, token, newPassword).then(
		() => {
			dispatch(withType(TYPES.RESET_PASSWORD_EXECUTE_END));
			dispatch(
				addToast(
					new Toast(`Password has been changed. You can now log in with your new password.`),
				),
			);
			container.history.push(routes.AUTH_LOGIN);
		},
		error => {
			dispatch(withType(TYPES.RESET_PASSWORD_EXECUTE_END, { error }));
		},
	);
};

export const generateUserPassword = userId => (dispatch, getState, container) => {
	dispatch(
		withType(TYPES.GENERATE_PASSWORD_REQUEST_START, {
			currentOp: TYPES.GENERATE_PASSWORD_REQUEST_START,
		}),
	);

	return api.generateUserPassword(container.http, userId).then(
		() => {
			dispatch(withType(TYPES.GENERATE_PASSWORD_REQUEST_END));
			dispatch(addToast(new Toast(`New password has been sent to user email.`)));
		},
		error => {
			dispatch(withType(TYPES.GENERATE_PASSWORD_REQUEST_END, { error }));
		},
	);
};

export const registerUser = payload => (dispatch, getState, container) => {
	dispatch(withType(TYPES.REGISTER_USER_START));

	return api.registerUser(container.http, payload).then(
		res => {
			dispatch(withType(TYPES.REGISTER_USER_END, res));
			dispatch(
				addToast(new Toast(`Sign up succesful. Check your inbox for a confirmation email.`)),
			);
			container.history.push(routes.AUTH_LOGIN);
		},
		error => {
			dispatch(withType(TYPES.REGISTER_USER_ERROR, { error }));
		},
	);
};
