import { IonResource, Resource } from 'cesium';
import { isEqual } from 'lodash';

import * as TYPES from '@/actions/action_types';
import { makeSiteUrlOrNull } from '@/lib/asset_router';
import * as routes from '@/lib/routes';
import { MEDIA_FORMATS } from '@/types/sites';
import { PROJECTIONS } from '@/types/vision';
import { CAMERA_CONTEXTS } from '@/views/cesium/constants';

import ReduceHelper, { createReducer, someChanged } from './reduce_helper';

/** @typedef {typeof INITIAL_STATE["vision"]} State */
/** @typedef {State["viewer"]} ViewerState */

/** @param {AssetVariant} variant */
function resolveVariantResource(variant) {
	const { cesium_ion_asset_id: cesiumIonAssetId, site } = variant;
	if (cesiumIonAssetId) {
		return IonResource.fromAssetId(cesiumIonAssetId);
	}
	if (site?.media_format !== MEDIA_FORMATS.tileset) {
		return null;
	}
	const tilesetUrl = `${makeSiteUrlOrNull(site?.url_path)}/tileset.json`;
	return Promise.resolve(new Resource({ url: tilesetUrl }));
}

/** @param {Asset} asset */
function assetMapper(asset) {
	asset.variants = asset.variants.map(variant => ({
		...variant,
		name: variant.site?.name,
		resource: variant.resource || resolveVariantResource(variant),
		type: variant.site?.type,
		url: makeSiteUrlOrNull(variant.site?.url_path),
	}));

	return asset;
}

const helper = new ReduceHelper('vision', 'id', assetMapper);

export const VIEWER_STATE = {
	/** @type {ViewerTool|string} */
	activeTool: null,

	colorPopupDroneImageId: null,

	downloading: false,

	droneImagesEnabled: false,

	firstPerson: false,

	googlePhotoRealisticTilesVisible: false,

	helpActive: false,

	isSplitActive: false,

	lastVisitedLibraryPath: routes.LIBRARY_ROOT,

	lastVisitedViewerPath: routes.VIEWER_ROOT,
	osmBuildingsVisible: false,

	/** @type {number} */
	primaryTourVariantId: null,

	/** @type {Projection|string} */
	projection: PROJECTIONS.mode_3d,

	satelliteView: false,

	/** @type {number} */
	selectedDocumentId: null,

	/** @type {number} */
	selectedImageId: null,

	tagsActive: false,

	timelineEnabled: false,
};

const INITIAL_STATE = {
	vision: {
		assetTypeFilter: ['property'],
		/** @type {{[id: number]: Asset}} */
		data: null,
		documents: null,
		loadError: null,
		loading: false,
		nextPage: null,
		op: null,
		storedCameraPlacements: Object.keys(CAMERA_CONTEXTS).reduce((cameraPlacements, placement) => {
			cameraPlacements[placement] = null;
			return cameraPlacements;
		}, {}),
		viewer: VIEWER_STATE,
	},
};

/**
 * @param {State} state
 * @param {{type: any} & Partial<State["viewer"]>} action
 */
const viewerSettingsReducer = (state = INITIAL_STATE.vision, action) => {
	const { viewer } = state;
	const { type, ...rest } = action;
	if (!someChanged(viewer, rest)) {
		return state;
	}

	return {
		...state,
		viewer: { ...viewer, ...rest },
	};
};

const saveCameraReducer = (state = INITIAL_STATE.vision, action) => {
	if (isEqual(action.cameraPlacement, state.storedCameraPlacements[action.cameraContext])) {
		return state;
	}

	const { cameraContext, cameraPlacement } = action;
	return {
		...state,
		storedCameraPlacements: { ...state.storedCameraPlacements, [cameraContext]: cameraPlacement },
	};
};

const assetTypeFilterReducer = (state = INITIAL_STATE.vision, action) => {
	if (state.assetTypeFilter.includes(action.assetType)) {
		const index = state.assetTypeFilter.indexOf(action.assetType);
		const assetType = [...state.assetTypeFilter];
		assetType.splice(index, 1);
		return {
			...state,
			assetTypeFilter: assetType,
		};
	}

	const { assetType } = action;
	const types = [...state.assetTypeFilter, assetType];

	return {
		...state,
		assetTypeFilter: types,
	};
};

const defaultReducer = createReducer(INITIAL_STATE.vision, {
	[TYPES.VISION_VIEWER_SETTINGS]: viewerSettingsReducer,
	[TYPES.VISION_STORE_CAMERA_PLACEMENT]: saveCameraReducer,
	[TYPES.SET_ASSET_TYPE_FILTER]: assetTypeFilterReducer,
});

function rootReducer(state = INITIAL_STATE, action) {
	const { vision, ...rest } = state;
	const nextState = defaultReducer(vision, action);
	const stateChanged = someChanged(vision, nextState);
	if (!stateChanged) {
		return state;
	}

	return {
		...rest,
		vision: { ...vision, ...nextState },
	};
}

export default {
	INITIAL_STATE,
	[TYPES.DATA_RESET_ALL]: helper.reset,
	[TYPES.SWITCH_COMPANY_END]: helper.reset,
	[TYPES.OP_RESET_ALL]: helper.opReset,
	[TYPES.VISION_GOT_DATA]: helper.gotData,
	[TYPES.VISION_LOAD_START]: helper.loadStart,
	[TYPES.VISION_LOAD_END]: helper.loadEnd,
	[TYPES.VISION_OP_START]: helper.opStart,
	[TYPES.VISION_OP_END]: helper.opEnd,
	[TYPES.VISION_OP_RESET]: helper.opReset,
	[TYPES.VISION_VIEWER_SETTINGS]: rootReducer,
	[TYPES.VISION_STORE_CAMERA_PLACEMENT]: rootReducer,
	[TYPES.SET_ASSET_TYPE_FILTER]: rootReducer,
};
