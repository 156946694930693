import { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { connect } from 'react-redux';
import Flow from '@flowjs/flow.js';
import filesize from 'file-size';
import { merge } from 'lodash';
import PropTypes from 'prop-types';

import { addToast } from '@/actions/application';
import { subscribe, unsubscribe } from '@/actions/sockets';
import { addFileUpload, endUpload, newResumableField, removeFileUpload } from '@/actions/uploads';
import { getApiEndpoint, getHeaders } from '@/lib/http';
import { uuid } from '@/lib/util';
import { getFileExplorerSubPath } from '@/selectors/file_explorer';
import { getFiles } from '@/selectors/uploads';
import FA from '@/types/font_awesome';
import { Toast, TOAST_TYPES } from '@/types/toast';
import { ASSET_STATUSES } from '@/types/uploads';
import IconButton from '@/views/widgets/IconButton';
import TextSpan from '@/views/widgets/presentation/TextSpan';
import TablePlain from '@/views/widgets/tables/TablePlain';

import './UploaderResumable.scss';

const CLASS = 'sv-UploaderResumable';

Flow.FlowChunk.prototype.getParamsOrig = Flow.FlowChunk.prototype.getParams;
Flow.FlowChunk.prototype.getParams = function () {
	const params = this.getParamsOrig();
	return Object.keys(params).reduce((agg, key) => {
		const newKey = key.replace('flow', 'resumable');
		return {
			...agg,
			[newKey]: params[key],
		};
	}, {});
};

class UploaderResumable extends Component {
	static propTypes = {
		autoRemove: PropTypes.bool,
		autoStart: PropTypes.bool,
		className: PropTypes.string,
		endUpload: PropTypes.func,
		endpoint: PropTypes.string.isRequired,
		files: PropTypes.array,
		identifier: PropTypes.string,
		maxFiles: PropTypes.number,
		onComplete: PropTypes.func,
		onFileAdded: PropTypes.func,
		onFileError: PropTypes.func,
		onFileRemoved: PropTypes.func,
		onFileSuccess: PropTypes.func,
		query: PropTypes.object,
	};

	static defaultProps = {
		autoRemove: true,
		autoStart: true,
		chunkRetryInterval: undefined,
		chunkSize: 5 * 1024 * 1024,
		endUpload: () => {},
		fileType: [],
		files: [],
		maxChunkRetries: 5,
		maxFileSize: undefined,
		minFileSize: undefined,
		onComplete: () => {},
		onFileAdded: () => {},
		onFileError: () => {},
		onFileRemoved: () => {},
		onFileSuccess: () => {},
		query: {},
		simultaneousUploads: 5,
	};

	componentDidMount() {
		this._mounted = true;

		const { resumableField } = this.props;
		if (!resumableField) {
			this.initializeResumablejs();
		} else {
			this.attachResumableEvents(resumableField);
		}
	}

	componentDidUpdate(oldProps, oldState) {
		const { resumableField } = this.props;
		const oldResumableField = oldProps.resumableField;
		const { props } = this;

		if (
			props.endpoint !== oldProps.endpoint ||
			props.query.folder_id !== oldProps.query.folder_id
		) {
			if (oldResumableField) {
				this.detachResumableEvents(oldResumableField);
			}
			if (resumableField) {
				this.attachResumableEvents(resumableField);
			}
		}

		if (!resumableField) {
			this.initializeResumablejs();
		}
	}

	componentWillUnmount() {
		const { endUpload, endpoint, files, resumableField } = this.props;
		if (resumableField) {
			this.detachResumableEvents(resumableField);
		}
		if (!files.length) {
			endUpload(endpoint, true);
		}
		this._mounted = false;
	}

	initializeResumablejs() {
		const {
			chunkRetryInterval,
			chunkSize,
			endpoint,
			fileType,
			maxChunkRetries,
			simultaneousUploads,
		} = this.props;

		const room = uuid();
		const resumableField = new Flow({
			chunkRetryInterval,
			chunkSize,
			fileType,
			headers: getHeaders('post', true),
			maxChunkRetries,
			query: this.getUploaderQueryParams,
			simultaneousUploads,
			target: getApiEndpoint(endpoint),
		});

		resumableField.room = room;
		this.attachResumableEvents(resumableField);
	}

	attachResumableEvents(resumableField) {
		const { endpoint, query } = this.props;
		this.detachResumableEvents(resumableField, true);
		const { room } = resumableField;
		resumableField.on('filesSubmitted', this.filesSubmitted);
		resumableField.on('progress', this.progress);
		resumableField.on('fileSuccess', this.fileSuccess);
		resumableField.on('fileError', this.fileError);
		resumableField.on('complete', () => this.complete(endpoint, resumableField));
		resumableField.opts.query = this.getUploaderQueryParams;
		this.props.subscribe(room);
		let params = query || {};
		if (typeof params !== 'object') params = {};
		const resumableFieldPayload = {
			...params,
			endpoint,
		};
		this.props.newResumableField(endpoint, resumableField, resumableFieldPayload);
	}

	detachResumableEvents(resumableField, force) {
		if (!resumableField.files) {
			return;
		}
		if (!resumableField || (resumableField.files.length && !force)) {
			return;
		}
		resumableField.events = [];

		if (!resumableField.files.length) {
			this.props.unsubscribe(resumableField.room);
		}
	}

	/**
	 * @param {flowjs.FlowFile} file
	 * @param {flowjs.FlowChunk} resumableChunk
	 * @param {boolean} isTest
	 * @memberof UploaderResumable
	 */
	getUploaderQueryParams = (file, resumableChunk, isTest) => {
		const { query, subPath } = this.props;
		const { flowObj } = file;
		const { room, shouldUnzip } = flowObj;

		return merge({}, query, { path: subPath, room, shouldUnzip });
	};

	fileSuccess = (file, message) => {
		try {
			message = JSON.parse(message);
		} catch (_) {
			console.error('Error parsing JSON', message);
		}
		this.props.onFileSuccess(file, message);
		if (this.props.autoRemove && !file.status) {
			this.removeFile(file);
		}
	};

	fileError = (file, ob) => {
		try {
			ob = JSON.parse(ob);
		} catch (_) {
			console.error('Error parsing JSON', ob);
		}
		this.props.onFileError(file, ob.error || ob);
		if (this.props.autoRemove) {
			this.removeFile(file);
		}
	};

	complete = (endpoint, resumableField) => {
		if (resumableField.isProcessing) {
			return;
		}
		if (this.props.autoRemove) {
			this.props.endUpload(endpoint);
		}

		if (!this._mounted) {
			return;
		}

		if (this.props.endpoint === endpoint) {
			this.props.onComplete(endpoint, this.props.identifier);
		}
	};

	progress = () => {
		if (!this._mounted) {
			return;
		}
		this.forceUpdate();
	};

	filesSubmitted = files => {
		files.forEach(file => {
			file.target = this.props.endpoint;
			file.status = ASSET_STATUSES.processing;
			file.flowObj.isProcessing = true;
			this.props.onFileAdded(this.props.endpoint, file);
			this._completeCalled = false;
			if (this.props.autoStart && !file.flowObj.isUploading()) {
				file.flowObj.upload();
			}
		});
	};

	removeFile(file) {
		file.cancel();
		this.props.onFileRemoved(file.target, file);
	}

	cancelAll = () => {
		this._completeCalled = true;
		this.props.resumableField.cancel();
		this.props.endUpload(this.props.endpoint);
	};

	renderChildren() {
		let uploadToMessage = null;
		if (this.props.subPath > 0) {
			uploadToMessage = (
				<p className="text-center">
					<strong>
						to <TextSpan info>{this.props.subPath}</TextSpan>
					</strong>
				</p>
			);
		}

		return (
			<div>
				<h3 className="text-center">
					<em>Drag files into this box. ZIP-s will be unpacked</em>
				</h3>
				{uploadToMessage}
			</div>
		);
	}

	renderFiles() {
		if (!this._mounted) {
			return null;
		}
		const { autoStart, files, resumableField } = this.props;
		const allComplete = files.length && files.every(f => f.isComplete());
		const uploadDisabled = allComplete || resumableField.isUploading();
		const tableBody = [];
		const cancelLabel = files.length > 1 ? 'Cancel all' : 'Cancel';

		files.forEach((file, i) => {
			const isComplete = file.isComplete();
			const { status } = file;
			const fileProgressPercentage = Math.round(file.progress() * 100);
			let percentageLabel =
				fileProgressPercentage > 0 ? `${fileProgressPercentage.toFixed(0)}%` : '-';
			if (isComplete && status) {
				percentageLabel = status;
			}
			tableBody.push(
				<tr key={file.uniqueIdentifier}>
					<td className="single-line shrink">{file.name}</td>
					<td className="single-line shrink">{filesize(file.size).human('jedec')}</td>
					<td className="text-center stretch">
						<ProgressBar
							label={percentageLabel}
							now={fileProgressPercentage}
							striped={isComplete}
						/>
					</td>
					<td className="single-line children-spacing text-right">
						<IconButton
							icon={FA.times}
							size="xsmall"
							variant="danger"
							onClick={() => this.removeFile(file)}
						/>
					</td>
				</tr>,
			);
		});

		return (
			<div className={`${CLASS}-files`}>
				<TablePlain bordered={false} striped={false}>
					<tbody>{tableBody}</tbody>
				</TablePlain>

				<div className="cancel-all-block single-line children-spacing text-right">
					<IconButton secondary disabled={allComplete} onClick={() => this.cancelAll()}>
						{cancelLabel}
					</IconButton>
					{autoStart ? null : (
						<IconButton disabled={uploadDisabled} onClick={() => resumableField.upload()}>
							Upload
						</IconButton>
					)}
				</div>
			</div>
		);
	}

	render() {
		const { children, files } = this.props;
		const className = `${CLASS} ${this.props.className || ''}`;

		if (!children && !files.length) {
			return null;
		}

		return <div className={className}>{files.length > 0 ? this.renderFiles() : children}</div>;
	}
}

const mapStateToProps = (state, props) => {
	const uploadTarget = state.uploads[props.endpoint];
	const identifier = props.identifier || props.endpoint;
	return {
		files: getFiles(state, props.endpoint),
		isProcessing: uploadTarget && uploadTarget.isProcessing,
		resumableField: uploadTarget && uploadTarget.resumableField,
		subPath: getFileExplorerSubPath(state, identifier),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		endUpload: (endpoint, wasEmpty) => {
			dispatch(endUpload(endpoint, wasEmpty));
		},
		newResumableField: (endpoint, resumableField, payload) => {
			dispatch(newResumableField(endpoint, resumableField, payload));
		},
		onFileAdded: (endpoint, file) => {
			dispatch(addFileUpload(endpoint, file));
		},
		onFileError: (file, errorOb) => {
			dispatch(
				addToast(
					new Toast(
						`Upload of ${file.name} has failed: ${errorOb.message || errorOb}`,
						TOAST_TYPES.danger,
					),
				),
			);
		},
		onFileRemoved: (endpoint, file) => {
			dispatch(removeFileUpload(endpoint, file));
		},
		subscribe: identifier => {
			dispatch(subscribe(identifier));
		},
		unsubscribe: identifier => {
			dispatch(unsubscribe(identifier));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UploaderResumable);
